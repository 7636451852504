<template>
  <v-btn-toggle
    v-model="periodSelect"
  >
    <v-btn
      :to="buildPath('active')"
      class="toggle"
      @change="$emit('input', 0)"
    >
      Active
    </v-btn>
    <v-btn
      :to="buildPath('inactive')"
      class="toggle"
      @change="$emit('input', 1)"
    >
      Inactive
    </v-btn>
    <v-btn
      :to="buildPath('unconfirmed')"
      class="toggle"
      @change="$emit('input', 2)"
    >
      Unconfirmed
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'MemberTypeFilter',
  props: {
    value: {
      type: [String, Object, Number],
      default: null,
    },
  },
  data() {
    return {
      periodSelect: this.value,
    };
  },
  computed: {
    selectedWorkspace() {
      return this.$store.getters.getSelectedWorkspace.slug;
    },
  },
  methods: {
    buildPath(path) {
      return `/${this.selectedWorkspace}/member/${path}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  ::v-deep.v-btn-toggle.v-item-group {
    background-color: transparent !important;
    padding: 4px;
    height: 48px;
    border-radius: $radius-3;
    border: 1px solid $grey-light;
    display: flex;
    justify-content: center;
    align-items: center;

    .v-btn.toggle {
      min-width: 70px;
      height: 40px !important;
      background-color: transparent;
      text-transform: none;
      border-radius: $radius-3;
      border: 2px solid transparent !important;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    .v-btn.v-btn {
      border: 2px solid transparent !important;
    }

    .v-item--active.v-btn--active.v-btn.v-btn {
      border-color: $primary !important;
      border-radius: $radius-3;
      background-color: transparent !important;
      color: $primary;

      &:before {
        background-color: transparent;
        padding: 5px;
      }
    }
  }
</style>
