<template>
  <section-wrap>
    <template #title>
      <div class="d-flex">
        <h3 class="section-title mr-5">
          Members
        </h3>
        <member-type-filter
          v-model="type"
        />
      </div>
    </template>

    <section-container
      :header="false"
    >
      <template #body>
        <router-view />
      </template>
    </section-container>
  </section-wrap>
</template>

<script>

import SectionWrap from '@/components/collections/SectionWrap/SectionWrap';
import SectionContainer from '@/components/collections/SectionContainer/SectionContainer';
import MemberTypeFilter from '@/views/Member/MemberTypeFilter';

export default {
  name: 'Member',
  components: {
    MemberTypeFilter,
    SectionContainer,
    SectionWrap,
  },
  data() {
    return {
      type: 0,
    };
  },
  computed: {
    selectedWorkspace() {
      return this.$store.getters.getSelectedWorkspace.slug;
    },
    getPermissions() {
      return this.$store.getters.getPermissions;
    },
  },
  watch: {
    selectedRows: {
      deep: true,
      handler(val) {
        this.snackbar = !!val.length;
      },
    },
    getPermissions: {
      handler(permissions) {
        if (!this.selectedWorkspace) this.$router.push('/dashboard');

        if (Object.keys(permissions).length > 0) {
          if (permissions.can_members) return;

          this.$router.push('/dashboard');
        }
      },
      immediate: true,
    },
  },
  created() {
    switch (this.$route.name) {
      case 'Active':
        this.period = 0;
        break;

      case 'Inactive':
        this.period = 1;
        break;

      case 'Unconfirmed':
        this.period = 2;
        break;

      default:
        this.period = 0;
    }
  },

};
</script>
