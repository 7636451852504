<template>
  <section class="section-wrap">
    <h3 v-if="title" class="section-title">
      {{ title }}
    </h3>
    <template>
      <slot name="title" />
    </template>
    <slot />
  </section>
</template>

<script>
export default {
  name: 'SectionWrap',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .section-wrap {
    padding: $type-4xl-lh $type-3xl-lh;
    height: 100%;
    min-height: 100vh;
  }
</style>
