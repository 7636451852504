<template>
  <v-container
    fluid
    class="section-content pa-0"
  >
    <div v-if="header" class="section-content-header">
      <slot name="header" />
    </div>
    <v-divider v-if="divider" />
    <div class="section-content-body">
      <slot name="body" />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'SectionContainer',
  props: {
    divider: Boolean,
    header: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .section-content {
    background-color: $white;
    border-radius: $radius-4;
    &-header {
      padding: $spacing-6 $spacing-8 $spacing-9;
    }
    &-body {
      padding: $spacing-6 $spacing-8 40px;
    }
  }
</style>
